import { useCurrentUserInfo } from '@air/utils-auth';

const isOrganizationSamlProvider = (providerName: string) => {
  const UUID_REGEX = /^[0-9a-f]{32}$/i;
  return typeof providerName === 'string' && UUID_REGEX.test(providerName);
};

export const useAccountUsedOrganizationSAML = () => {
  const { data: user } = useCurrentUserInfo();

  const accountUsedOrganizationSAML = user
    ? user?.attributes?.identities?.some((identity) => {
        return identity.providerType === 'SAML' && isOrganizationSamlProvider(identity.providerName);
      })
    : false;

  return {
    accountUsedOrganizationSAML,
  };
};
