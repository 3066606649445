import { Workspaces } from '@air/api';
import { WorkspaceListResponse } from '@air/api/types';
import { isAdminDotAirDotInc } from '@air/sysadmin';
import { useIsLoggedIn } from '@air/utils-auth';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useAccountProvisionerContext } from '~/components/AccountProvisioner/providers/AccountProvisionerProvider';

export const WORKSPACES_LIST = 'WORKSPACES_LIST';

export const getWorkspacesListKey = (params?: Parameters<typeof Workspaces.list>['0']) => {
  return [WORKSPACES_LIST, params];
};

export const useWorkspaces = () => {
  const { isLoggedIn } = useIsLoggedIn();
  const { hasError: hasAccountProvisioningError, isFetchPending } = useAccountProvisionerContext();
  const selectWorkspaces = useCallback(
    (data: WorkspaceListResponse) =>
      hasAccountProvisioningError ? data?.filter((workspace) => !workspace.organization) : data,
    [hasAccountProvisioningError],
  );

  return useQuery({
    queryKey: getWorkspacesListKey(),
    queryFn: () => Workspaces.list(),
    enabled: isLoggedIn && !isFetchPending && !isAdminDotAirDotInc(),
    select: selectWorkspaces,
  });
};
