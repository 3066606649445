import { Tasks } from '@air/api';
import { matchesAirror, NOT_FOUND } from '@air/errors';
import { useIsLoggedIn } from '@air/utils-auth';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { useAccountUsedOrganizationSAML } from '~/swr-hooks/account/useAccountUsedOrganizationSAML';

const ACCOUNT_PROVISIONER = 'ACCOUNT_PROVISIONER';

export const getAccountProvisionerKey = () => {
  return [ACCOUNT_PROVISIONER];
};

export const useAccountProvisioner = () => {
  const { isLoggedIn } = useIsLoggedIn();
  const { accountUsedOrganizationSAML } = useAccountUsedOrganizationSAML();
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);

  return useQuery({
    queryKey: getAccountProvisionerKey(),
    queryFn: async () => {
      const tasks = await Tasks.listTasks({
        options: {
          limit: 1,
          type: 'AccountProvisioner',
        },
      });
      const result = tasks.items[0] ?? null;
      setRefetchInterval(result && ['pending', 'active'].includes(result.status) ? 2000 : false);
      return result;
    },
    retry: (failureCount, error) => {
      // if the error is not found, we don't want to retry.
      if (matchesAirror(error, NOT_FOUND)) {
        return false;
      }
      return failureCount < 3;
    },
    refetchInterval,
    staleTime: Infinity,
    enabled: isLoggedIn && accountUsedOrganizationSAML,
  });
};
