import { Tasks } from '@air/api';
import { useIsLoggedIn } from '@air/utils-auth';
import { reportErrorToBugsnag } from '@air/utils-error';
import { useQueryClient } from '@tanstack/react-query';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { usePreviousDistinct, useSessionStorage } from 'react-use';

import { useAccountUsedOrganizationSAML } from '~/swr-hooks/account/useAccountUsedOrganizationSAML';
import { isDevOrTestStage } from '~/swr-hooks/utils';
import { getWorkspacesListKey } from '~/swr-hooks/workspaces/useWorkspaces';

import { useAccountProvisioner } from '../hooks/useAccountProvisioner';

type AccountProvisionerProviderProps = {
  children: React.ReactNode;
};

type AccountProvisioner = Awaited<ReturnType<typeof Tasks.listTasks<undefined, 'AccountProvisioner'>>>['items'][number];

type AccountProvisionerProviderContextValue = {
  accountProvisioner: AccountProvisioner | null;
  continuedWithAccountProvisioningError: boolean;
  setContinuedWithAccountProvisioningError: (value: boolean) => void;
  inProgress: boolean;
  hasError: boolean;
  error?: Error | null;
  isFetchPending: boolean;
};

const defaultValue: AccountProvisionerProviderContextValue = {
  accountProvisioner: null,
  continuedWithAccountProvisioningError: false,
  setContinuedWithAccountProvisioningError: () => {
    /**/
  },
  inProgress: false,
  hasError: false,
  isFetchPending: true,
};

const AccountProvisionerProviderContext = createContext(defaultValue);

export const AccountProvisionerProvider = ({ children }: AccountProvisionerProviderProps) => {
  const { isLoggedIn, isLoggedInFetched } = useIsLoggedIn();
  const {
    data: accountProvisioner = null,
    isFetched: isAccountProvisionerFetched,
    isError,
    error,
  } = useAccountProvisioner();
  const { accountUsedOrganizationSAML } = useAccountUsedOrganizationSAML();
  const prevStatus = usePreviousDistinct(accountProvisioner?.status);
  const queryClient = useQueryClient();
  const hasError = isError || (!!accountProvisioner && ['failed', 'exception'].includes(accountProvisioner.status));
  const inProgress = !!accountProvisioner && ['pending', 'active'].includes(accountProvisioner.status);
  const isFetchPending =
    accountUsedOrganizationSAML &&
    !(isLoggedInFetched && !isLoggedIn) &&
    !(isLoggedInFetched && isLoggedIn && isAccountProvisionerFetched);

  const [continuedWithAccountProvisioningError, setContinuedWithAccountProvisioningError] = useSessionStorage<boolean>(
    'continuedWithAccountProvisioningError',
  );

  // Invalidate workspaces list query when accountProvisioner status changes to 'succeeded'
  useEffect(() => {
    const currentStatus = accountProvisioner?.status;
    if (prevStatus && currentStatus === 'succeeded' && prevStatus !== currentStatus) {
      queryClient.invalidateQueries({ queryKey: getWorkspacesListKey() });
    }
  }, [prevStatus, accountProvisioner?.status, queryClient]);

  const value = useMemo<AccountProvisionerProviderContextValue>(
    () => ({
      accountProvisioner,
      continuedWithAccountProvisioningError,
      setContinuedWithAccountProvisioningError,
      inProgress,
      hasError,
      error,
      isFetchPending,
    }),
    [
      accountProvisioner,
      continuedWithAccountProvisioningError,
      error,
      hasError,
      inProgress,
      isFetchPending,
      setContinuedWithAccountProvisioningError,
    ],
  );

  return (
    <AccountProvisionerProviderContext.Provider value={value}>{children}</AccountProvisionerProviderContext.Provider>
  );
};

export function useAccountProvisionerContext() {
  const context = useContext(AccountProvisionerProviderContext);

  if (context === defaultValue) {
    const error = 'AccountProvisionerProviderContext used outside of AccountProvisionerProvider';

    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
}
